export enum Environment {
  Development,
  DevelopmentWithProduction,
  Homolog,
  Production,
}

interface ConfigEnvironment {
  admin: Environment
  auth: Environment
  base: Environment
  chat: Environment
  vehicle: Environment
}

class Config {
  static get restrictTime(): { hours: number[]; minutes: number[] } {
    const hours = [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23,
    ]
    const minutes = [0, 15, 30, 45]

    return { hours, minutes }
  }

  static get role() {
    return {
      admin: 1,
      company: 3,
      finance: 6,
      firm: 8,
      operator: 7,
      user: 2,
    }
  }

  static isRoleCompany(roleId: number) {
    if (
      roleId === Config.role.company ||
      roleId === Config.role.finance ||
      roleId === Config.role.operator
    ) {
      return true
    }

    return false
  }

  static getEnvironmentWithUrl() {
    const url = new URL(window.location.href)

    switch (url.host) {
      case "localhost:3000":
        return Environment.Development
      case "localhost:3500":
        return Environment.DevelopmentWithProduction
      case "beta.closecar.com.br":
        return Environment.Homolog
      case "closecar.com.br":
        return Environment.Production
      default:
        return Environment.Production
    }
  }

  static get environment(): ConfigEnvironment {
    const environment = Config.getEnvironmentWithUrl()

    return {
      admin: environment,
      auth: environment,
      base: environment,
      chat: environment,
      vehicle: environment,
    }
  }
}

export default Config
