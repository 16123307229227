export const pt = {
  translation: {
    account_already_exist: "Já existe uma conta para esse email.",
    account_created_error: "Ops, parece que há algo faltando em seu cadastro.",
    account_not_authorized:
      "Ops! Você não está autorizado ou houve algum erro na solicitação.",
    booking_error_account_not_completed:
      "Você deve completar todos os dados de cadastro antes de efetuar uma reserva",
    booking_error_account_not_upload_driver_license:
      "Você deve enviar um foto da sua carteira de motorista. Arquivo de ser em PNG ou PDF.",
    booking_error_cpf_invalid: "Este CPF não é válido.",
    booking_error_cupom_code_invalid: "Ops! Este cupom não é valido.",
    booking_error_cvv: "Os dados do cartão estão incorretos.",
    booking_error_dropoff_invalid:
      "Você selecionou um local de entrega inválido.",
    booking_error_insurance_validate_error:
      "Ops! Esse veículo não pode ser validado junto ao nosso seguro.",
    booking_error_pickup_invalid:
      "Você selecionou um local de retirada inválida.",
    change: "Trocar",
    change_date_mobile: "Clique para alterar",
    change_date_mobile_title: "Alterar datas",
    charges: "Pagamento",
    choose_mobile_pickup: "Data e hora de Retirada",
    choose_mobile_return: "Data e hora de Devolução",
    choose_pickup: "Data de Retirada",
    choose_place: "Lugar de retirada",
    choose_return: "Data de Devolução",
    choose_time_pickup: "Hora de Retirada",
    choose_time_return: "Hora de Devolução",
    chosen_date: "Data e hora de retirada e entrega",
    city: "Cidade",
    close: "Fechar",
    company_branch_error_airport_registered:
      "Este aeroporto já está cadastrado.",
    company_not_approved:
      "A sua empresa ainda está em fase aprovação, aguarde até o processo de validação ser finalizado.",
    confirm: "Confirmar",
    cupom_error_invalid: "Ops! Este cupom não é valido.",
    date_not_valid: "Essa data não é válida",
    discount: "Desconto ( {discount}% )",
    drop_off: "Entrega",
    dropoff_place: "Local de devolução",
    favorite: "Favoritar",
    favorited: "Favoritado",
    generic_error:
      "Ops! Acorreu um erro inesperado, tente novamente ou entre em contato com o suporte.",
    go_my_reservation: "Ir para minhas reservas",
    go_website: "Ir para o site",
    insurance_price: "Preço do seguro",
    login: "Fazer Login",
    manual_select_the_date:
      "Selecione a data e hora de inicio e fim da reserva manual",
    my_account: "Minha Conta",
    open_day_friday: "Sexta-feira",
    open_day_monday: "Segunda-feira",
    open_day_saturday: "Sábado",
    open_day_sunday: "Domingo",
    open_day_thursday: "Quinta-feira",
    open_day_tuesday: "Terça-feira",
    open_day_wednesday: "Quarta-feira",
    payment_not_authorized: "Ops, não foi possível aprovar o pagamento.",
    pick_up: "Retirada",
    pickup_place: "Local de retirada",
    place: "Local",
    price_per_day: "Preço por dia",
    price_per_hour: "Preço por hora",
    quote_forbidden: "A cotação não pode ser criada com uma conta empresarial.",
    register_already_exist: "Este registro já existe.",
    rent: "Alugar",
    rent_done_info:
      "Pronto! Seu aluguel foi feito com sucesso! Agora é só ir até a loja e buscar o veículo na data e hora solicitados.",
    rent_error_protection: "Você deve selecionar pelo menos 1 proteção.",
    reservation_code: "Código da sua reserva",
    reservation_code_movida: "Leve esse código na hora da sua reserva.",
    reserver_near_you: "Reserve os melhores veículos próximos a você.",
    search: "Procurar",
    see_on_map: "Ver no mapa",
    see_that_store: "Ver carros",
    select_the_date: "Seleciona a data e hora de retirada e entrega",
    tax_administration_price: "Taxa de admin.",
    taxes_fees: "Impostos e Taxas",
    term_of_use_must_be_accepted: "Você precisar aceitar os termos de uso.",
    total_days:
      "{day, plural, =0 {Preço final} =1 {Preço por 1 dia} other {Preço por # dias}}",
    total_hours:
      "{hour, plural, =0 {Preço final} =1 {Preço por 1 hora} other {Preço por # horas}}",
    total_price: "Preço Total",
    ts_company_question_car_condition:
      "O cliente entregou o veículo com as codições acordadas?",
    ts_company_question_user_rating: "Qual a avaliação do cliente?",
    ts_vehicle_automatic: "Automático",
    ts_vehicle_manual: "Manual",
    type_name_city: "digite o nome da cidade",
    validationerror_acceptTerms: "Você deve aceitar os termos de uso",
    validationerror_cnhExpirationDate:
      "Você deve informar a data de validade da CNH",
    validationerror_cnhNumber: "Você deve informar o número da CNH",
    validationerror_description: "Você deve incluir uma descrição.",
    validationerror_doors: "Você deve informar o número de portas",
    validationerror_email: "Você deve informar o email",
    validationerror_facebook: "Você deve incluir http:// ou https://",
    validationerror_image:
      "Você deve selecionar ou fazer o upload de uma imagem",
    validationerror_instagram: "Você deve incluir http:// ou https://",
    validationerror_linkedin: "Você deve incluir http:// ou https://",
    validationerror_manufactureYear: "Ano de fabricação inválido",
    validationerror_modelYear: "Ano de modelo inválido",
    validationerror_name: "Você deve informar o nome",
    validationerror_password: "Você deve escolher uma senha",
    validationerror_phone: "Você deve informar o telefone",
    validationerror_protections: "Você deve selecionar pelo menos 1 proteção",
    validationerror_seats: "Você deve informar o número de lugares",
    validationerror_site: "Você deve incluir http:// ou https://",
    validationerror_vehicleUpload: "O veículo deve ter pelo menos 1 foto.",
    vehicle_already_has_booking: "Este veículo já está alugado nesta data.",
    vehicle_group_already_registered: "Grupo de veículo já cadastrada.",
    vehicle_group_not_available:
      "Não há veículos disponíveis no grupo para essa data.",
    vehicle_in_all_places: "Veículos em qualquer lugar do Brasil",
  },
}

export const DEFAULT_ERROR_MESSAGE = "account_not_authorized"
export const GENERIC_ERROR_MESSAGE = "generic_error"
