import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"

import { MenuOutlined } from "@ant-design/icons"
import { Avatar, Drawer } from "antd"
import Config from "libs/config"
import { useStorage } from "libs/storage"
import { AppState } from "store"
import * as CheckTokenAction from "store/checktoken/Action"

import "./index.scss"

const Header: React.FC<any> = ({
  checkToken,
  withBackground,
  withLogo,
  withMenu = true,
}) => {
  const { t } = useTranslation()
  const { authorization } = useStorage()
  const history = useHistory()
  const [menu, showMenu] = useState(false)

  useEffect(() => {
    checkToken()
  }, [])

  const goHome = () => {
    history.push("/")
  }

  const openMenu = () => {
    showMenu(true)
  }

  const closeMenu = () => {
    showMenu(false)
  }

  const goAccountOrLogin = () => {
    let url = "/account/user"

    switch (authorization?.role) {
      case Config.role.admin:
        url = "/account/admin/dashboard"
        break
      case Config.role.user:
        url = "/account/user"
        break
      case Config.role.company:
        url = "/account/company/dashboard"
        break
      case Config.role.operator:
        url = "/account/company/dashboard"
        break
      case Config.role.finance:
        url = "/account/company/dashboard"
        break
    }

    history.push(authorization ? url : "/signin")
  }

  const [name] = authorization?.name || "U"

  return (
    <div
      className={`molecule-header ${withBackground && "molecule-header__background"}`}
    >
      <div>
        {withLogo && (
          <div className="molecule-header__logo" onClick={goHome}>
            <img
              className="molecule-header__logo"
              src="https://53cars.s3-sa-east-1.amazonaws.com/logo_principal.png"
            />
          </div>
        )}

        {/** This is the mobile menu */}
        {/* {withMenu && (
          <div onClick={openMenu} className="molecule-header__mobile-menu">
            <MenuOutlined className="molecule-header__menu-mobile" />
            <div className="molecule-header__logo-mobile-box">
              {withLogo && (
                <img src="https://53cars.s3-sa-east-1.amazonaws.com/logo_principal.png" className="molecule-header__logo-mobile" />
              )}
            </div>
            
            <div className="_text-2 molecule-header__menu-mobile-login">
              { authorization ? 
              (<div onClick={goAccountOrLogin}>
                Minha conta

                <Avatar className="_ml-4" size="large" style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                  {name}
                </Avatar>
              </div>)
              :
              t('login')}
              </div>
          </div>
        )} */}
      </div>

      <div className="molecule-header__menu">
        {/** This is the desktop menu */}
        {withMenu && (
          <>
            <div
              className="molecule-header__menu-item"
              onClick={goAccountOrLogin}
            >
              {authorization ? (
                <div className="_flex-diplay _flex-row _align-items-center">
                  <div>
                    <Avatar
                      className="_mr-2"
                      size="small"
                      style={{ backgroundColor: "#fde3cf", color: "#f56a00" }}
                    >
                      {name}
                    </Avatar>
                  </div>
                  <div>
                    <div>{authorization?.name}</div>
                    <div className="_text-1">{t("my_account")}</div>
                  </div>
                </div>
              ) : (
                t("login")
              )}
            </div>
            {!authorization && (
              <div
                className="molecule-header__menu-item _ml-4"
                onClick={() => history.push("/signup")}
              >
                Cadastre-se
              </div>
            )}
            {/* {!authorization && (
              <div onClick={() => history.push('/signup/company')} className="molecule-header__menu-item _ml-4">Sou locadora</div>
            )} */}
          </>
        )}
      </div>

      {/* {withMenu && (
        <Drawer
            title={<img src="https://53cars.s3-sa-east-1.amazonaws.com/logo_principal.png" className="molecule-header__logo-mobile" />}
            placement="left"
            closable={true}
            onClose={closeMenu}
            visible={menu}>
            <p onClick={() => history.push('/')}>Página Inicial</p>
            <p onClick={() => history.push('/signin')}>Login</p>
            <p onClick={() => history.push('/signup')}>Cadastre-se</p>
            <p onClick={() => history.push('/signup/company')}>Sou locadora</p>
          </Drawer>
      )} */}
    </div>
  )
}

const mapStateToProps = ({ checkToken }: AppState) => {
  return {
    loading: checkToken?.loading,
  }
}

const mapDispatchToProps = {
  checkToken: CheckTokenAction.checkToken,
}

Header.defaultProps = {
  withBackground: true,
  withLogo: true,
}

// TODO: Fix redux
export default connect(mapStateToProps, mapDispatchToProps)(Header) as any
