import React from 'react'
import { Form, Input } from 'antd'
import Text from 'components/atom/text'

interface FormTextAreaProps {
  label?: string;
  name: string;
  rows?: number;
  required?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  min?: number;
  max?: number;
}

const FormTextArea: React.FC<FormTextAreaProps> = (props) => {
  const rules = []

  if (props?.required) {
    rules.push({ required: true, message: 'Este campo não pode ser vazio.' })
  }

  if (props?.min) {
    rules.push({ min: props?.min, message: `Este campo deve ter no mínimo ${props?.min} letras.`})
  }

  if (props?.max) {
    rules.push({ max: props?.max, message: `Este campo deve ter no máximo ${props?.max} letras.`})
  }

  return (
    <div className={props.hidden ? "_display-none" : ""}>
      <Text className="_text-2 _font">{props.label}{props.required && ' *'}</Text>
      
      <Form.Item name={props.name} hasFeedback rules={rules}>
        <Input.TextArea rows={props.rows} showCount={!!props?.max} maxLength={props?.max} disabled={props.disabled || false} hidden={props.hidden || false}/>
      </Form.Item>
    </div>
  )
}

FormTextArea.defaultProps = {
  rows: 3
}

export default FormTextArea