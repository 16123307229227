import React from "react"

import { Select as AntSelect } from "antd"

const { Option } = AntSelect

function Select(props: any) {
  return (
    <AntSelect
      defaultValue={props.defaultValue}
      onChange={props.handleChange}
      style={{ width: "100%" }}
    >
      {props.data.map((item: any) => (
        <Option key={item.value} value={item.value}>
          {item.label}
        </Option>
      ))}
    </AntSelect>
  )
}

export default Select
