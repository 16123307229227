export enum StateMachineType {
  NotStarted = "NotStarted",
  Loading = "Loading",
  Loaded = "Loaded",
  Error = "Error",
}

class StateMachine {
  static in(
    currentState: StateMachineType | undefined | null,
    desiredState: StateMachineType,
  ) {
    return currentState === desiredState
  }

  static isNotStarted(currentState?: StateMachineType) {
    return currentState === StateMachineType.NotStarted
  }

  static isLoading(currentState?: StateMachineType) {
    return currentState === StateMachineType.Loading
  }

  static isLoadingOrNotStarted(currentState?: StateMachineType) {
    return (
      currentState === StateMachineType.Loading ||
      currentState === StateMachineType.NotStarted
    )
  }

  static isLoaded(currentState?: StateMachineType) {
    return currentState === StateMachineType.Loaded
  }

  static isError(currentState?: StateMachineType) {
    return currentState === StateMachineType.Error
  }
}

export default StateMachine
