import React, { Suspense } from 'react'
import Image from 'components/atom/image'

const ImageLoad = (props: any) => {
  
  const url = props.url || ""

  return <div style={{ width: props.width || "auto", height: props.height || "auto" }} >
    <Suspense fallback={<div className="_text-center">Carregando a imagem...</div>}>
      <Image url={url} alt={props?.alt} className={props.className} ableToOpen={props.ableToOpen} title={props.title} />
    </Suspense>
  </div>
};

export default ImageLoad;