import React from "react"

import { Form, Select } from "antd"
import Text from "components/atom/text"

type FormSelectOption = {
  value: string | number
  text: string
}

type FormSelectOptionGroup = {
  group: string
  options: FormSelectOption[]
}

interface FormSelectProps {
  allowClear?: boolean
  bordered?: boolean
  disabled?: boolean
  extras?: string
  label?: string
  loading?: boolean
  name: string
  onSelect?: (value: any, option: any) => void
  option?: FormSelectOption[] | FormSelectOptionGroup[]
  placeholder?: string
  required?: boolean
  selectClassName?: string
  showSearch?: boolean
}

const { OptGroup, Option } = Select

const FormSelect: React.FC<FormSelectProps> = (props) => {
  const rules = []

  if (props?.required) {
    rules.push({ message: "Este campo não pode ser vazio.", required: true })
  }

  return (
    <div>
      {props.label && (
        <Text className="_text-2 _font">
          {props.label}
          {props.required && " *"}
        </Text>
      )}

      <Form.Item
        extra={props.extras}
        hasFeedback
        name={props.name}
        rules={rules}
      >
        <Select
          allowClear={props.allowClear || false}
          bordered={props.bordered || true}
          className={props.selectClassName}
          disabled={props.disabled || false}
          loading={props.loading || false}
          onSelect={props.onSelect}
          optionFilterProp="children"
          placeholder={props.placeholder}
          showSearch={props.showSearch || false}
          size="large"
        >
          {props?.option?.map((opt) => {
            if (opt.hasOwnProperty("group")) {
              return (
                <OptGroup key={opt.group} label={opt?.group}>
                  {opt.options.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.text}
                    </Option>
                  ))}
                </OptGroup>
              )
            } else {
              return (
                <Option key={opt.value} value={opt.value}>
                  {opt.text}
                </Option>
              )
            }
          })}
        </Select>
      </Form.Item>
    </div>
  )
}

export default FormSelect
