import React from "react"

import { Form, DatePicker, TimePicker } from "antd"
import Text from "components/atom/text"
import { Moment } from "moment"

interface FormDatePickerProps {
  disabled?: boolean
  hidden?: boolean
  label?: string
  max?: number
  min?: number
  name: string
  onSelect?: (date: Moment) => void
  onSelectTime?: (date: Moment) => void
  required?: boolean
  withTime?: boolean
}

const FormDatePicker: React.FC<FormDatePickerProps> = (props) => {
  const rules = []

  if (props?.required) {
    rules.push({ message: "Este campo não pode ser vazio.", required: true })
  }

  if (props?.min) {
    rules.push({
      message: `Este campo deve ter no mínimo ${props?.min} letras.`,
      min: props?.min,
    })
  }

  if (props?.max) {
    rules.push({
      max: props?.max,
      message: `Este campo deve ter no máximo ${props?.max} letras.`,
    })
  }

  return (
    <div className={props.hidden ? "_display-none" : ""}>
      <Text className="_text-2 _font">
        {props.label}
        {props.required && " *"}
      </Text>

      <Form.Item hasFeedback name={props.name} rules={rules}>
        <DatePicker
          disabled={props.disabled || false}
          format="DD/MM/YYYY"
          onSelect={props.onSelect}
          size="large"
          style={{
            width: "100%",
          }}
        />
      </Form.Item>

      {props.withTime && (
        <Form.Item hasFeedback name={`${props.name}Time`} rules={rules}>
          <TimePicker
            disabled={props.disabled || false}
            format={"HH:mm"}
            minuteStep={30}
            onOk={props.onSelectTime}
            size="large"
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
      )}
    </div>
  )
}

export default FormDatePicker
