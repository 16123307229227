// @ts-nocheck

import styled from 'styled-components'

export enum Space {
  None = 0,
  XXSmall = 2,
  XSmall = 4,
  Small = 8,
  Medium = 16,
  Large = 24,
  XLarge = 32,
  XXLarge = 48,
  XXXLarge = 64,
}

type SpaceBoxProps = {
  'mt-none'?: boolean,
  'mt-xxsmall'?: boolean,
  'mt-xsmall'?: boolean,
  'mt-small'?: boolean,
  'mt-medium'?: boolean,
  'mt-large'?: boolean,
  'mt-xlarge'?: boolean,
  'mt-xxlarge'?: boolean,
  'mt-xxxlarge'?: boolean,
}

const propKey = {
  'mt': 'margin-top'
}

const propValue = {
  'none': '0px',
  'xxsmall': '2px',
  'xsmall': '4px',
  'small': '8px',
  'medium': '16px',
  'large': '24px',
  'xlarge': '32px',
  'xxlarge': '48px',
  'xxxlarge': '64px'
}

type BoxProps = SpaceBoxProps

const diggestProps = (props: any) => {
  const keys = Object.keys(props)
  
  return keys.map(prop => {
    const [key, value]: any = prop.split("-")

    return { [propKey[key]]: propValue[value] }
  })

  //return props['mt-none'] && { 'margin-top': '16px' }
}

const Box = styled.div<BoxProps>`
  ${props => diggestProps(props)};
`;

export default Box