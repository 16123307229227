import FetchApi from "libs/fetch"
import { StateMachineType } from "libs/statemachine"
import { Dispatch } from "redux"
import * as ActionUtility from "store/utilities/actionUtility"

import { FavoriteCompanyParams } from "./Action"
import ActionType from "./ActionType"

export const accountInfo = async () => {
  const fetch = await FetchApi.get(`/v1/account/info`, true)

  return {
    data: fetch.data,
    hasError: FetchApi.hasError(fetch.status),
  }
}

export const setLoading = (dispatch: Dispatch, payload: StateMachineType) =>
  dispatch(
    ActionUtility.createAction(ActionType.ACCOUNT_INFO_DATA_LOADING, payload),
  )

export const setData = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.ACCOUNT_INFO_DATA, payload))

export const setError = (dispatch: Dispatch, payload: any) =>
  dispatch(
    ActionUtility.createAction(ActionType.ACCOUNT_INFO_DATA_ERROR, payload),
  )

export const accountUpdate = async (params: any) => {
  const fetch = await FetchApi.put(`/v1/account/update`, params, true)

  return {
    data: fetch.data,
    hasError: FetchApi.hasError(fetch.status),
  }
}

export const setLoadingUpdate = (
  dispatch: Dispatch,
  payload: StateMachineType,
) =>
  dispatch(
    ActionUtility.createAction(ActionType.ACCOUNT_UPDATE_LOADING, payload),
  )

export const setErrorUpdate = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.ACCOUNT_UPDATE_ERROR, payload))

export const favoriteCompany = async (body: FavoriteCompanyParams) => {
  const fetch = await FetchApi.post(`/v1/account/company/favorite`, body, true)

  return {
    data: fetch.data,
    hasError: FetchApi.hasError(fetch.status),
  }
}

export const accountVerify = async ({ email }: { email: string }) => {
  const fetch = await FetchApi.post(`/v1/account/check`, { email }, true)

  return {
    data: fetch.data,
    hasError: FetchApi.hasError(fetch.status),
  }
}

export const setLoadingVerify = (
  dispatch: Dispatch,
  payload: StateMachineType,
) =>
  dispatch(
    ActionUtility.createAction(ActionType.ACCOUNT_VERIFY_LOADING, payload),
  )

export const setErrorVerify = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.ACCOUNT_VERIFY_ERROR, payload))

export const setDataVerify = (dispatch: Dispatch, payload: any) =>
  dispatch(ActionUtility.createAction(ActionType.ACCOUNT_VERIFY_DATA, payload))

export const accountContact = async ({
  bestContact,
  email,
  name,
  phone,
}: {
  name: string
  email: string
  bestContact: string
  phone: string
}) => {
  const fetch = await FetchApi.post(
    `/v1/firm/account/contact`,
    { bestContact, email, name, phone },
    false,
  )

  return {
    data: fetch.data,
    hasError: FetchApi.hasError(fetch.status),
  }
}
