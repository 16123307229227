import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"

import { StateMachineType } from "@/libs/statemachine"
import { AppDispatch, RootState } from "@/store"

import {
  accountInfo,
  accountUpdate,
  accountVerify,
  accountContact,
  favoriteCompany,
  accountLogout,
} from "./accountSlice"

export const useAccountStore = () => {
  const dispatch = useDispatch<AppDispatch>()
  const accountState = useSelector((state: RootState) => state.account)

  const executeAccountInfo = useCallback(
    () => dispatch(accountInfo()),
    [dispatch],
  )

  const executeAccountUpdateAsync = useCallback(
    async (params: any) => dispatch(accountUpdate(params)),
    [dispatch],
  )

  const executeAccountUpdate = useCallback(
    (params: any) => dispatch(accountUpdate(params)),
    [dispatch],
  )

  const executeAccountVerify = useCallback(
    (email: string) => dispatch(accountVerify({ email })),
    [dispatch],
  )

  const executeAccountContact = useCallback(
    (body: any) => {
      dispatch(accountContact(body))
    },
    [dispatch],
  )

  const executeFavoriteCompany = useCallback(
    (body: any) => {
      dispatch(favoriteCompany(body))
    },
    [dispatch],
  )

  const executeAccountLogout = useCallback(() => {
    dispatch(accountLogout())
  }, [dispatch])

  return {
    accountInfo: {
      data: accountState.info?.data || {},
      viewState: accountState.info?.viewState || StateMachineType.NotStarted,
    },
    accountUpdate: {
      error: accountState.update?.error,
      viewState: accountState.update?.viewState,
    },
    accountVerify: {
      data: accountState.verify?.data,
      error: accountState.verify?.error,
      viewState: accountState.verify?.viewState,
    },
    executeAccountContact,
    executeAccountInfo,
    executeAccountLogout,
    executeAccountUpdate,
    executeAccountUpdateAsync,
    executeAccountVerify,
    executeFavoriteCompany,
  }
}
