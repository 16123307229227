import Config, { Environment } from "libs/config"
import history from "libs/history"
import Storage from "libs/storage"

class FetchApi {
  static get HttpSuccessfulOk() {
    return 200
  }

  static get HttpSuccessfulCreated() {
    return 201
  }

  static get HttpErrorBadRequest() {
    return 400
  }

  static get HttpErrorUnauthorized() {
    return 401
  }

  static get HttpErrorForbidden() {
    return 403
  }

  static get HttpErrorNotFound() {
    return 404
  }

  static categorize(service: string) {
    if (service.includes("/v1/firm")) {
      return "firm"
    } else if (service.includes("/v1/auth")) {
      return "auth"
    } else if (service.includes("/v1/vehicle")) {
      return "vehicle"
    } else if (service.includes("/v1/admin")) {
      return "admin"
    } else if (service.includes("/v1/chat")) {
      return "chat"
    }
  }

  static base(service: string) {
    const entity = FetchApi.categorize(service)

    switch (entity) {
      case "auth":
        return FetchApi.baseAuth
      case "vehicle":
        return FetchApi.baseVehicle
      case "admin":
        return FetchApi.baseAdmin
      case "chat":
        return FetchApi.baseChat
      default:
        return FetchApi.baseApi
    }
  }

  static get baseApi() {
    switch (Config.environment.base) {
      case Environment.Development:
        return "http://localhost:3002/local"
      case Environment.DevelopmentWithProduction:
        return "http://localhost:3002/prod"
      case Environment.Homolog:
        return "https://lzq5at5ejd.execute-api.sa-east-1.amazonaws.com/dev"
      case Environment.Production:
        return "https://s197mrj6j4.execute-api.sa-east-1.amazonaws.com/prod"
      default:
        return "https://lzq5at5ejd.execute-api.sa-east-1.amazonaws.com/dev"
    }
  }

  static get baseVehicle() {
    switch (Config.environment.vehicle) {
      case Environment.Development:
        return "http://localhost:3018/local"
      case Environment.DevelopmentWithProduction:
        return "http://localhost:3018/prod"
      case Environment.Homolog:
        return "https://h6s06xrcse.execute-api.sa-east-1.amazonaws.com/dev"
      case Environment.Production:
        return "https://67kgwnqf25.execute-api.sa-east-1.amazonaws.com/prod"
      default:
        return "https://h6s06xrcse.execute-api.sa-east-1.amazonaws.com/dev"
    }
  }

  static get baseAuth() {
    switch (Config.environment.auth) {
      case Environment.Development:
        return "http://localhost:3014/local"
      case Environment.DevelopmentWithProduction:
        return "http://localhost:3014/prod"
      case Environment.Homolog:
        return "https://99x1ajj0h6.execute-api.sa-east-1.amazonaws.com/dev"
      case Environment.Production:
        return "https://avtlrlf7x4.execute-api.sa-east-1.amazonaws.com/prod"
      default:
        return "https://99x1ajj0h6.execute-api.sa-east-1.amazonaws.com/dev"
    }
  }

  static get baseAdmin() {
    switch (Config.environment.admin) {
      case Environment.Development:
        return "http://localhost:3026/local"
      case Environment.DevelopmentWithProduction:
        return "http://localhost:3026/prod"
      case Environment.Homolog:
        return "https://al904q9fhj.execute-api.sa-east-1.amazonaws.com/dev"
      case Environment.Production:
        return "https://9f2nywdx9l.execute-api.sa-east-1.amazonaws.com/prod"
      default:
        return "https://al904q9fhj.execute-api.sa-east-1.amazonaws.com/dev"
    }
  }

  static get baseChat() {
    switch (Config.environment.chat) {
      case Environment.Development:
        return "http://localhost:3030/local"
      case Environment.DevelopmentWithProduction:
        return "http://localhost:3030/prod"
      case Environment.Homolog:
        return "https://al904q9fhj.execute-api.sa-east-1.amazonaws.com/dev"
      case Environment.Production:
        return "https://9f2nywdx9l.execute-api.sa-east-1.amazonaws.com/prod"
      default:
        return "https://al904q9fhj.execute-api.sa-east-1.amazonaws.com/dev"
    }
  }

  static hasError(code: number) {
    return code >= FetchApi.HttpErrorBadRequest
  }

  static async refreshToken(
    service: any,
    params: any,
    hasAuth = false,
    method: any,
  ) {
    const authorization = Storage.authorization()

    const options: any = {
      headers: { Authorization: authorization?.refreshToken },
      method: "GET",
    }

    const response = await fetch(
      `${FetchApi.baseAuth}/v1/auth/refresh`,
      options,
    )
    const data = await response.json()

    if (response.status === FetchApi.HttpSuccessfulOk) {
      Storage.authorization(data)

      if (method === "GET") {
        return await FetchApi.get(service, hasAuth)
      } else if (method === "POST") {
        return await FetchApi.post(service, params, hasAuth)
      } else if (method === "PUT") {
        return await FetchApi.put(service, params, hasAuth)
      }
    } else {
      Storage.clear()
      const params: any = history.location.state

      const urlToRedirect = params?.urlToRedirect
        ? params.urlToRedirect
        : history.location.pathname

      history.replace("/signin", {
        dataToRedirect: params?.dataToRedirect,
        messageToRedirect: null,
        redirect: true,
        urlToRedirect,
      })

      return {
        data,
        status: FetchApi.HttpErrorUnauthorized,
      }
    }
  }

  static async get(service: any, hasAuth = false, options = {}): Promise<any> {
    let fetchOptions: any = {
      method: "GET",
      ...options,
    }

    if (hasAuth) {
      const authorization = Storage.authorization()
      fetchOptions = {
        ...fetchOptions,
        headers: { Authorization: authorization?.accessToken },
      }
    }

    const url = FetchApi.base(service)
    const response = await fetch(`${url}${service}`, fetchOptions)

    if (response.status === FetchApi.HttpErrorUnauthorized) {
      return FetchApi.refreshToken(service, null, hasAuth, "GET")
    } else {
      const data = await response.json()

      return {
        data,
        status: response.status,
      }
    }
  }

  static async post(
    service: any,
    params: any,
    hasAuth: boolean = false,
  ): Promise<any> {
    let headers: any = {
      "Content-Type": "application/json",
    }

    if (hasAuth) {
      const authorization = Storage.authorization()
      headers = { ...headers, Authorization: authorization?.accessToken }
    }
    const url = FetchApi.base(service)
    const response = await fetch(`${url}${service}`, {
      body: JSON.stringify(params),
      headers,
      method: "POST",
    })

    if (response.status === FetchApi.HttpErrorUnauthorized) {
      return FetchApi.refreshToken(service, params, hasAuth, "POST")
    } else {
      const data = await response.json()

      return {
        data,
        status: response.status,
      }
    }
  }

  static async put(
    service: any,
    params: any,
    hasAuth: boolean = false,
  ): Promise<any> {
    let headers: any = {
      "Content-Type": "application/json",
    }

    if (hasAuth) {
      const authorization = Storage.authorization()
      headers = { ...headers, Authorization: authorization?.accessToken }
    }

    const url = FetchApi.base(service)
    const response = await fetch(`${url}${service}`, {
      body: JSON.stringify(params),
      headers,
      method: "PUT",
    })

    if (response.status === FetchApi.HttpErrorUnauthorized) {
      return FetchApi.refreshToken(service, params, hasAuth, "POST")
    } else {
      const data = await response.json()

      return {
        data,
        status: response.status,
      }
    }
  }

  static async delete(
    service: any,
    params: any,
    hasAuth: boolean = false,
  ): Promise<any> {
    let headers: any = {
      "Content-Type": "application/json",
    }

    if (hasAuth) {
      const authorization = Storage.authorization()
      headers = { ...headers, Authorization: authorization?.accessToken }
    }

    const url = FetchApi.base(service)
    const response = await fetch(`${url}${service}`, {
      body: JSON.stringify(params),
      headers,
      method: "DELETE",
    })

    if (response.status === FetchApi.HttpErrorUnauthorized) {
      return FetchApi.refreshToken(service, params, hasAuth, "POST")
    } else {
      const data = await response.json()

      return {
        data,
        status: response.status,
      }
    }
  }

  static async upload(url: string, type: string, file: any): Promise<any> {
    const response = await fetch(url, {
      body: file,
      method: "PUT",
    })

    return {
      status: response.status,
    }
  }

  static async uploadBase64(
    url: string,
    type: string,
    file: any,
  ): Promise<any> {
    const response = await fetch(url, {
      body: file,
      headers: {
        "Content-Encoding": "base64",
        "Content-Type": "image/jpeg",
      },
      method: "PUT",
    })

    return {
      status: response.status,
    }
  }

  static async download(
    service: any,
    fileName: string | undefined,
    hasAuth = false,
  ): Promise<any> {
    let options: any = {
      method: "GET",
    }

    if (hasAuth) {
      const authorization = Storage.authorization()
      options = {
        ...options,
        headers: { Authorization: authorization?.accessToken },
      }
    }

    const url = FetchApi.base(service)
    const response = await fetch(`${url}${service}`, options)

    if (response.status === FetchApi.HttpErrorUnauthorized) {
      return FetchApi.refreshToken(service, null, hasAuth, "GET")
    } else {
      const data = await response.blob()

      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", fileName ? fileName : "CloseCarDocumento")
      document.body.appendChild(link)
      link.click()
      link?.parentNode?.removeChild(link)

      return {
        data,
        status: response.status,
      }
    }
  }
}

export default FetchApi
