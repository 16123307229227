import React, { PropsWithChildren } from "react"

type TextProps = PropsWithChildren<any>

function Text(props: TextProps) {
  return <span {...props}>{props.children}</span>
}

export function Headline1({ children }: any) {
  return <Text className="text-6xl sm:text-7xl md:text-8xl">{children}</Text>
}

export function Headline2({ children }: any) {
  return <Text className="text-5xl sm:text-6xl md:text-7xl">{children}</Text>
}

export function Headline3({ children }: any) {
  return <Text className="text-4xl sm:text-5xl md:text-6xl">{children}</Text>
}

export function Headline4({ children }: any) {
  return <Text className="text-3xl sm:text-4xl md:text-5xl">{children}</Text>
}

export function Headline5({ children }: any) {
  return <Text className="text-2xl sm:text-3xl md:text-4xl">{children}</Text>
}

export function Headline6({ children }: any) {
  return <Text className="text-xl sm:text-2xl md:text-3xl">{children}</Text>
}

export function Title({ children }: any) {
  return <Text className="text-lg sm:text-xl md:text-2xl">{children}</Text>
}

export function Subtitle({ children }: any) {
  return <Text className="text-base sm:text-lg md:text-xl">{children}</Text>
}

export function Subtitle2({ children }: any) {
  return <Text className="text-base sm:text-lg md:text-xl">{children}</Text>
}

export function Body({ children }: any) {
  return <Text className="text-sm sm:text-base md:text-lg">{children}</Text>
}

export function Caption({ children }: any) {
  return <Text className="text-xs sm:text-sm md:text-base">{children}</Text>
}

type TextComponent = typeof Text & {
  Headline1: React.FC<TextProps>
  Headline2: React.FC<TextProps>
  Headline3: React.FC<TextProps>
  Headline4: React.FC<TextProps>
  Headline5: React.FC<TextProps>
  Headline6: React.FC<TextProps>
  Title: React.FC<TextProps>
  Subtitle: React.FC<TextProps>
  Subtitle2: React.FC<TextProps>
  Body: React.FC<TextProps>
  Caption: React.FC<TextProps>
}

const TextWithSubcomponents = Text as TextComponent

TextWithSubcomponents.Headline1 = Headline1
TextWithSubcomponents.Headline2 = Headline2
TextWithSubcomponents.Headline3 = Headline3
TextWithSubcomponents.Headline4 = Headline4
TextWithSubcomponents.Headline5 = Headline5
TextWithSubcomponents.Headline6 = Headline6
TextWithSubcomponents.Title = Title
TextWithSubcomponents.Subtitle = Subtitle
TextWithSubcomponents.Subtitle2 = Subtitle2
TextWithSubcomponents.Body = Body
TextWithSubcomponents.Caption = Caption

export default TextWithSubcomponents
