import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit"
import FetchApi from "libs/fetch"
import { StateMachineType } from "libs/statemachine"
import Storage from "libs/storage"

interface State {
  info: {
    viewState: StateMachineType
    data: any
    error: boolean
  }
  update: {
    viewState: StateMachineType
    error: boolean
  }
  verify: {
    data: any
    viewState: StateMachineType
    error: boolean
  }
}
const initialState: State = {
  info: {
    data: {},
    error: false,
    viewState: StateMachineType.NotStarted,
  },
  update: {
    error: false,
    viewState: StateMachineType.NotStarted,
  },
  verify: {
    data: {},
    error: false,
    viewState: StateMachineType.NotStarted,
  },
}

export const accountInfo = createAsyncThunk("account/info", async () => {
  const fetch = await FetchApi.get(`/v1/account/info`, true)
  return fetch.data
})

export const accountUpdate = createAsyncThunk(
  "account/update",
  async (params: any) => {
    const fetch = await FetchApi.put(`/v1/account/update`, params, true)
    return fetch.data
  },
)

export const accountVerify = createAsyncThunk(
  "account/verify",
  async ({ email }: { email: string }) => {
    const fetch = await FetchApi.post(`/v1/account/check`, { email }, true)
    return fetch.data
  },
)

export const accountContact = createAsyncThunk(
  "account/contact",
  async (body: any) => {
    const fetch = await FetchApi.post(`/v1/firm/account/contact`, body, false)
    return fetch.data
  },
)

export const favoriteCompany = createAsyncThunk(
  "account/favoriteCompany",
  async (body: any) => {
    const fetch = await FetchApi.post(
      `/v1/account/company/favorite`,
      body,
      true,
    )
    return fetch.data
  },
)

const accountSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(accountInfo.pending, (state) => {
        state.info.viewState = StateMachineType.Loading
      })
      .addCase(accountInfo.fulfilled, (state, action: PayloadAction<any>) => {
        state.info.viewState = StateMachineType.Loaded
        state.info.data = action.payload
      })
      .addCase(accountInfo.rejected, (state) => {
        state.info.viewState = StateMachineType.Error
        state.info.error = true
      })
      .addCase(accountUpdate.pending, (state) => {
        state.update.viewState = StateMachineType.Loading
      })
      .addCase(accountUpdate.fulfilled, (state) => {
        state.update.viewState = StateMachineType.Loaded
        state.update.error = false
      })
      .addCase(accountUpdate.rejected, (state) => {
        state.update.viewState = StateMachineType.Error
        state.update.error = true
      })
      .addCase(accountVerify.pending, (state) => {
        state.verify.viewState = StateMachineType.Loading
      })
      .addCase(accountVerify.fulfilled, (state, action: PayloadAction<any>) => {
        state.verify.viewState = StateMachineType.Loaded
        state.verify.data = action.payload
        state.verify.error = false
      })
      .addCase(accountVerify.rejected, (state) => {
        state.verify.viewState = StateMachineType.Error
        state.verify.error = true
      })
  },
  initialState,
  name: "account",
  reducers: {
    accountLogout: (state) => {
      state.info = initialState.info
      Storage.clear()
    },
  },
})

export const { accountLogout } = accountSlice.actions
export default accountSlice.reducer
