import React from "react"

function Card(props: any) {
  const onClick = () => {
    props.onClick?.()
  }

  return (
    <div
      className={`bg-white border-slate-50 rounded-xl p-4${props.className ? ` ${props.className}` : ""}`}
      onClick={onClick}
    >
      {props.children}
    </div>
  )
}

export default Card
