import React, { useRef, useState } from "react"
import { connect } from "react-redux"

import { DeleteOutlined } from "@ant-design/icons"
import { PlusOutlined, SyncOutlined } from "@ant-design/icons"
import { Form, Input } from "antd"
import Button from "components/atom/button"
import ImageLoad from "components/atom/image_load"
import { AppState } from "store"
import * as DocumentAction from "store/document/Action"

import "./styles.scss"

const FormImage: React.FC<any> = ({
  data,
  disabled = false,
  documentType = null,
  fileKey,
  name,
  onRemoved,
  onSuccess,
  position,
  remove,
  title = null,
  upload,
  url = null,
}) => {
  const fileInput: any = useRef(null)
  const document = data?.find((doc: any) => doc.fileKey === fileKey)
  const [uploadState, setUploadState] = useState<any>({
    fileKey: document?.fileKey,
    loading: null,
    position,
    success: null,
    url: document?.url || url,
  })

  const onHandleUpload = (e: any) => {
    if (uploadState.loading || disabled) {
      e.preventDefault()
    }

    fileInput.current.click()
  }

  const submitUpload = async () => {
    const { current } = fileInput

    if (current && current.files.length > 0) {
      const [file] = current.files
      setUploadState({ ...uploadState, loading: true })
      const data = await upload({
        documentType,
        file,
        position,
        type: file.type,
      })

      if (!data.hasError) {
        setUploadState({
          ...uploadState,
          fileKey: data.fileKey,
          loading: false,
          position,
          success: true,
          url: data.url,
        })
        onSuccess && onSuccess(data)
      } else {
        setUploadState({ ...uploadState, loading: false })
      }
    }
  }

  const removeUpload = () => {
    remove({ fileKey: uploadState.fileKey })
    setUploadState({ loading: null, success: null, url: null })
    onRemoved()
  }

  return (
    <div>
      <Form.Item name={name}>
        <Input size="large" type="hidden" />
        <div className="molecule-form-image-content">
          {uploadState.success || uploadState?.url ? (
            <div>
              <ImageLoad
                ableToOpen={true}
                title={title}
                url={uploadState?.url}
              />
              <Button
                block
                className="_mt-4"
                icon={<DeleteOutlined />}
                onClick={() => removeUpload()}
                theme="outline-primary"
              >
                Remover
              </Button>
            </div>
          ) : (
            <div className="molecule-form-image-box-container">
              <div className="molecule-form-image-box" onClick={onHandleUpload}>
                {uploadState?.loading ? (
                  <SyncOutlined spin />
                ) : (
                  <PlusOutlined className="molecule-form-image-box__icon" />
                )}
                <input
                  className="molecule-form-image-box__input-file"
                  onChange={submitUpload}
                  ref={fileInput}
                  type="file"
                />
              </div>
              {title && (
                <div className="_text-3 _font-weight-bold _mt-4 _text-center">
                  {" "}
                  {title}{" "}
                </div>
              )}
            </div>
          )}
        </div>
      </Form.Item>
    </div>
  )
}

const mapStateToProps = ({ document }: AppState) => {
  return {
    data: document?.data,
    loading: document?.loading,
    success: document?.success,
  }
}

const mapDispatchToProps = {
  remove: DocumentAction.remove,
  upload: DocumentAction.upload,
}

export default connect(mapStateToProps, mapDispatchToProps)(FormImage)
